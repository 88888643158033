<template>
  <div class="mdui-container-fluid">
    <div class="mdui-row">
      <div
        class="mdui-col-xs-12 mdui-col-sm-8 mdui-col-offset-sm-2 mdui-col-lg-6 mdui-col-offset-lg-3"
      >
        <div class="mdui-card mdui-m-y-5">
          <div class="mdui-card-content mdui-typo">
            <div class="mdui-row">
              <div class="mdui-col-xs-12">
                <div
                  class="mdui-typo-headline-opacity mdui-text-center mdui-m-y-3"
                >
                  关于
                </div>
              </div>
              <div class="mdui-col-xs-12">
                <div
                  class="mdui-text-center mdui-typo-title-opacity mdui-m-y-2"
                >
                  五十音助手
                </div>
                <div class="mdui-text-center mdui-typo-body-1 mdui-m-y-1">
                  软件项目管理 课程设计。<br />
                  起源于项目经理学习日语入门选修课时的个人需求。
                </div>
                <div
                  class="mdui-typo-subheading-opacity mdui-text-center mdui-m-y-2"
                >
                  作者
                </div>
                <div class="mdui-text-center mdui-typo-body-1 mdui-m-y-1">
                  T.X.<br />
                  <small>不愿透露姓名的</small> Administrator<br />
                  CSY<br />
                  GYW
                </div>
              </div>
              <div
                class="mdui-col-md-4 mdui-col-offset-md-4 mdui-col-xs-6 mdui-col-offset-xs-3"
              >
                <img
                  class="mdui-img-fluid mdui-img-circle mdui-m-y-2"
                  src="~@/assets/img/avatar.jpg"
                />
              </div>
              <div class="mdui-col-xs-12 mdui-typo-body-1">
                <p class="mdui-text-center">
                  欢迎关注项目经理的
                  <a href="https://github.com/foldblade">Github</a>
                  <br />欢迎为
                  <a href="https://github.com/foldblade/gojuuon_helper"
                    >本项目</a
                  >
                  Star
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  inject: ["globalVariable"],
};
</script>
